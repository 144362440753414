import { FilterTypeConstants } from '../constants';

const initialState = {
    items: ''
}
export function filterTypeReducer(state = initialState, action) {
    switch (action.type) {
        case FilterTypeConstants.GET_FILTERTYPE:
            return {
                items: action.filterVal
            };
        case FilterTypeConstants.SET_FILTERTYPE:
            return {
                items: action.filterVal
            };
        default:
            return state
    }
}