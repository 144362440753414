import { ZipCodeConstants } from '../constants';


export function zipCodeReducer(state = {}, action) {
    switch (action.type) {
        case ZipCodeConstants.GET_ZIPCODE:
            return {
                items: action.zip
            };
        case ZipCodeConstants.SET_ZIPCODE:
            return {
                items: action.zip
            };
        default:
            return state
    }
}