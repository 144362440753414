import { LocationIDMapConstant } from '../constants';


export function locationIdMapReducer(state = {}, action) {
    switch (action.type) {
        case LocationIDMapConstant.GET_LOCATION_ID_MAP:
            return {
                items: action.LocId
            };
        case LocationIDMapConstant.SET_LOCATION_ID_MAP:
            return {
                items: action.LocId
            };
        default:
            return state
    }
}