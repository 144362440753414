import { LocationDirectionsConstants } from '../constants';

export function locationDirectionsReducer(state = {}, action) {
    switch (action.type) {
        case LocationDirectionsConstants.GETALL_LOC_DIRECTIONS_REQUEST:
            return {
                loading: true
            };
        case LocationDirectionsConstants.GETALL_LOC_DIRECTIONS_SUCCESS:
            return {
                items: action.locDirections
            };
        case LocationDirectionsConstants.GETALL_LOC_DIRECTIONS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}