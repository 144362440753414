import { CoordLocationConstants } from '../constants';


export function setCoordLocationReducer(state = {}, action) {
    switch (action.type) {
        case CoordLocationConstants.GET_COORD_LOC:
            return {
                items: action.coordLocName
            };
        case CoordLocationConstants.SET_COORD_LOC:
            return {
                items: action.coordLocName
            };
        default:
            return state
    }
}