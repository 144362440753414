import { MapLocGroupConstants } from '../constants';

export function mapLocGroupReducer(state = {}, action) {
    switch (action.type) {
        case MapLocGroupConstants.GET_MAP_GROUP:
            return {
                items: action.map
            };
        case MapLocGroupConstants.SET_MAP_GROUP:
            return {
                items: action.map
            };
        default:
            return state
    }
}