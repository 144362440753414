import { MapUIConstants } from '../constants';

export function mapUIReducer(state = {}, action) {
    switch (action.type) {
        case MapUIConstants.GET_MAPUI:
            return {
                items: action.mapUI
            };
        case MapUIConstants.SET_MAPUI:
            return {
                items: action.mapUI
            };
        default:
            return state
    }
}