import { ProductDetailsConstants } from '../constants';

export function productDetailsReducer(state = {}, action) {
    switch (action.type) {
        case ProductDetailsConstants.GET_PRODUCTDETAILS:
            return {
                loading: true
            };
        case ProductDetailsConstants.GET_PRODUCTDETAILS_SUCCESS :
            return {
                items: action.productDetails
            };
        case ProductDetailsConstants.GET_PRODUCTDETAILS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}