import { ProductConstants } from '../constants';

export function productReducer(state = {}, action) {
    switch (action.type) {
        case ProductConstants.GET_PRODUCT:
            return {
                items: action.productId
            };
        case ProductConstants.SET_PRODUCT:
            return {
                items: action.productId
            };
        default:
            return state
    }
}