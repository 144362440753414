import { HMapConstants } from '../constants';

export function hMapReducer(state = {}, action) {
    switch (action.type) {
        case HMapConstants.GET_HMAP:
            return {
                items: action.HMap
            };
        case HMapConstants.SET_HMAP:
            return {
                items: action.HMap
            };
        default:
            return state
    }
}