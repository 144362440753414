import { TabConstants } from '../constants';

export function tabReducer(state = {}, action) {
    switch (action.type) {
        case TabConstants.GET_TAB:
            return {
                items: action.direction
            };
        case TabConstants.SET_TAB:
            return {
                items: action.direction
            };
        default:
            return state
    }
}