import { SliderDistConstants } from './slider.constants';

let calcRadius;

if ((document.getElementsByTagName("bsi-product-locator")).length !== 0) {
    let getTagAttr = document.getElementsByTagName("bsi-product-locator")[0];
    
    if (getTagAttr.hasAttribute('default_radius')) {
        calcRadius = getTagAttr.getAttribute("default_radius");
    } else {
        calcRadius = '5';
    }
} else {
    calcRadius = '5'
}
const initialState = {
    items: calcRadius
}
export function sliderDistReducer(state = initialState, action) {
    switch (action.type) {
        case SliderDistConstants.GET_SLIDERDIST:
            return {
                items: action.dist
            };
        case SliderDistConstants.SET_SLIDERDIST:
            return {
                items: action.dist
            };
        default:
            return state
    }
}