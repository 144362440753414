import { LocationsPageNumConstants } from './locationsPageNum.constants';
const initialState = {
    items: 1
}
export function locationsPageNumReducer(state = initialState, action) {
    switch (action.type) {
        case LocationsPageNumConstants.GET_PAGENUM_REQUEST:
            return {
                items: action.pNum
            };
        case LocationsPageNumConstants.SET_PAGENUM_SUCCESS:
            return {
                items: action.pNum
            };
        default:
            return state
    }
}