import { CountryConstants } from '../constants';

export function countryReducer(state = {}, action) {
    switch (action.type) {
        case CountryConstants.GET_COUNTRY:
            return {
                items: action.country
            };
        case CountryConstants.SET_COUNTRY:
            return {
                items: action.country
            };
        default:
            return state
    }
}