import { MapConstants } from '../constants';

export function mapReducer(state = {}, action) {
    switch (action.type) {
        case MapConstants.GET_MAP:
            return {
                items: action.map
            };
        case MapConstants.SET_MAP:
            return {
                items: action.map
            };
        default:
            return state
    }
}