import { LocationIDConstant } from '../constants';


export function locationIdReducer(state = {}, action) {
    switch (action.type) {
        case LocationIDConstant.GET_LOCATION_ID:
            return {
                items: action.LocId
            };
        case LocationIDConstant.SET_LOCATION_ID:
            return {
                items: action.LocId
            };
        default:
            return state
    }
}