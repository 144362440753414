import { ProductListingConstants } from '../constants';

export function productListingReducer(state = {}, action) {
    switch (action.type) {
        case ProductListingConstants.GETALL_PRODUCTS_REQUEST:
            return {
                loading: true
            };
        case ProductListingConstants.GETALL_PRODUCTS_SUCCESS:
            return {
                items: action.productListing
            };
        case ProductListingConstants.GETALL_PRODUCTS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}