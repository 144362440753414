import { CountryCodeConstants } from '../constants';

export function countryCodeReducer(state = {}, action) {
    switch (action.type) {
        case CountryCodeConstants.GET_COUNTRY_CODE_REQUEST:
            return {
                loading: true
            };
        case CountryCodeConstants.GET_COUNTRY_CODE_SUCCESS:
            return {
                items: action.countryCode
            };
        case CountryCodeConstants.GET_COUNTRY_CODE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}