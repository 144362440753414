import { DirectionConstants } from '../constants';

export function directionReducer(state = {}, action) {
    switch (action.type) {
        case DirectionConstants.GET_DIRECTION:
            return {
                items: action.direction
            };
        case DirectionConstants.SET_DIRECTION:
            return {
                items: action.direction
            };
        default:
            return state
    }
}