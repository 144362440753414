import { DirectionCoordsConstants } from '../constants';

export function directionCoordsReducer(state = {}, action) {
    switch (action.type) {
        case DirectionCoordsConstants.GET_DIRECTIONCOORDS:
            return {
                items: action.dircoords
            };
        case DirectionCoordsConstants.SET_DIRECTIONCOORDS:
            return {
                items: action.dircoords
            };
        default:
            return state
    }
}