import { SetCoordinatesConstants } from '../constants';


export function setCoordinatesReducer(state = {}, action) {
    switch (action.type) {
        case SetCoordinatesConstants.GET_COORDINATES:
            return {
                items: action.coordinates
            };
        case SetCoordinatesConstants.SET_COORDINATES:
            return {
                items: action.coordinates
            };
        default:
            return state
    }
}