import { VendorsConstants } from '../constants';

export function vendorReducer(state = {}, action) {
    switch (action.type) {
        case VendorsConstants.GETALL_VENDORS_REQUEST:
            return {
                loading: true
            };
        case VendorsConstants.GETALL_VENDORS_SUCCESS:
            return {
                items: action.vendors
            };
        case VendorsConstants.GETALL_VENDORS_FAILURE:
            return {
                error: action.error
            };
        case VendorsConstants.RESET_VENDORS:
            return {
                error: action.vendors
            };
        default:
            return state
    }
}